input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}

.login-box input:-webkit-autofill,
.login-box input:-webkit-autofill:hover,
.login-box input:-webkit-autofill:focus,
.login-box input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #f5f5f5;
}

.custom-side-bar,
.custom-scrollbar {
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.custom-side-bar {
  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
}

.custom-side-bar:hover,
.mobile-scrollbar,
.custom-scrollbar:hover {

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #989898;
    border-radius: 20px;
  }
}

.sidebar-item :hover {
  margin: 0 !important;
}

.ant-menu-item {
  padding-left: 18px !important;
}

.ant-menu-item-selected {
  padding-left: 13px !important;
}


.ant-menu-item-selected .sidebar-text {
  font-weight: 600;
  color: #c2002f;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
  margin-left: auto !important;
}

.sidebar-text {
  margin-left: 10px;
  font-weight: 500;
  color: gray;
  font-size: 13px;
}

.sidebar-icon {
  color: gray !important;
}

.custom-content {
  position: fixed !important;
  top: 60px !important;
  left: 240px;
  right: 0px !important;
  bottom: 0px !important;
  padding: 50px 0px 24px 0px !important;
  margin: 0px !important;
  overflow: scroll !important;
  background: white !important;
}

.ant-menu-item-selected {
  border-left: 5px solid #c2002f !important;
}

.sidebar-open {
  left: 0;
}

.shipper_layout .ant-layout {
  /* background-color: rgb(241, 241, 244); */
  background-color: white;
}


.ant-switch.ant-switch-checked {
  background-color: green !important;
}

.ant-drawer-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* .ant-select-selector{
  background-color: #f5f5f5;
} */

.custom-input-light-gray .ant-select-selector {
  background-color: #f5f5f5 !important;
}

.file-box {
  display: flex;
  justify-content: space-between;
  background-color: antiquewhite;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.img-container {
  overflow: hidden;
  height: 150px;
}

.store-img-container {
  overflow: hidden;
}

.img-container .edit-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  display: none;
}

.img-container:hover .edit-button {
  display: block;
}

.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon .ant-btn-icon {
  line-height: 0;
}

.marker {
  background-size: cover;
  width: 22px;
  height: 30px;
  cursor: pointer;
}

.ninja-icon {
  background-image: url('./asset/ninja_station.png');
}

.ninja-partner-icon {
  background-image: url('./asset/partner_ninja_station.png');
}

.ninja-citymall-icon {
  background-image: url('./asset/partner_ninja_station_violet.png');
}

.map-container {
  position: relative;
  height: 85vh;
  width: 100%;
  margin: 0 auto;
}

.loading-layer {
  position: absolute;
  top: 0;
  bottom: 37px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(208, 208, 208, 0.8);
  font-size: 1.5em;
  color: #333;
}

.noti-badge.ant-badge.ant-badge-status {
  line-height: normal !important;
}

.ant-select .ant-select-arrow {
  color: rgba(33, 33, 33, 1);
}

.pointer {
  cursor: pointer;
}

.custom-modal-2 {
  width: 700px !important;
}

.custom-modal-2 .ant-modal-content {
  padding: 20px 0;
}

.custom-upload .ant-upload.ant-upload-select {
  width: 350px !important;
}

/* swipper */

.swiper {
  width: 100%;
  height: 70%;
}

.swiper-wrapper {
  padding-bottom: 1rem;
}

.icon-wrapper {
  position: relative;
  padding: 0 35px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -8px;
  font-size: 25px;
  line-height: 1;
}

.icon-wrapper .prev-icon-wrapper {
  color: #F7B935;
}

.icon-wrapper .next-icon-wrapper {
  color: #10C200;
}

.icon-wrapper .anticon:first-child {
  inset-inline-start: 0;
}

.icon-wrapper .anticon:last-child {
  inset-inline-end: 0;
}

.ant-pagination .ant-pagination-item-active a{
  color: #c2002f;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #c2002f;
}

.ant-card .ant-card-head-title{
  white-space: pre-line;
}

/* Media query for large screens (desktop devices) */
@media screen and (max-width: 992px) {
  .custom-side-bar {
    display: none;
  }
}

/* Media query for small screens (mobile devices) */
@media screen and (max-width: 960px) {
  .logo-center {
    text-align: center;
  }

  .custom-side-bar {
    display: none;
  }

  .custom-content {
    left: 0 !important;
  }
}


/* tutorial */
.tutorial-video-container {
  position: relative;
  height: 210px;
  overflow: hidden;
}

.tutorial-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 0.1px solid #f1f1f1;
  background-color: #f1f1f1;
}

.tutorial-video-container h5 {
  position: absolute;
  top: 300px;
}

.ant-qrcode {
  width: auto !important;
}

/* step style */

.step-full-width-title .ant-steps-item-title {
  width: 100% !important;
}

/* step style */

.icon-circle {
  width: 45px;
  height: 45px;
  border-radius: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .img-container {
    height: 120px;
  }
}

.card-shadow {
  /* EDEDED */

  box-shadow: 0px 4px 20px #ead7d7 !important;
  border-radius: 15px;
}

.blue-row {
  background-color: #f1f1f1;
  /* Blue color */
}

.black-row {
  background-color: white;
  /* Black color */
}

/* .custom-modal .ant-modal-content {
  border-radius: 20px;
  padding: 10px;
} */