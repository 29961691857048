.file-box {
    display: flex;
    justify-content: space-between;
    background-color: antiquewhite;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
}

.valid-tag {
    font-size: 14px;
    font-weight: 600;
    background: rgb(0, 100, 0);
    padding: 6px 10px;
    color: white;
    border-radius: 24px;
    margin-right: 10px;
}

.invalid-tag {
    font-size: 14px;
    font-weight: 600;
    background: red;
    padding: 6px 10px;
    color: white;
    border-radius: 24px;
}