.running-text-container {
    width: 100%;
    overflow: hidden;
}

.running-text {
    font-size: 25px;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
    
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}