.login-box input {
    font-size: 15px;
    font-weight: 500;
    border: none !important;
    border-radius: 9px !important;
    background-color: #f5f5f5;
}

.login-box .ant-input {
    height: 40px;
    border: none !important;
    background-color: #f5f5f5 !important;
    border-radius: 9px !important;
}

.login-form-button {
    text-align: center;
}

.login-form-button .ant-btn {
    border-radius: 50px !important;
    padding: 0px 60px;
}
